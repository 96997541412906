<template>
  <section class="tile">
    <div class="flex__between">
      <h2>User Info</h2>
      <button class="btn btn__red btn__sm" @click="toggleEditing">{{ editing ? "Cancel" : "Edit"}}</button>
    </div>
    <p>Be advised, the email entered below must match the user's email when they sign in or they won't have access to this account.</p>
    <div class="user-profile--info__grid m-tb-sm">
      <label :class="[errors.first ? 'form--error' : null]">
        <span class="text__bold">First Name*</span>
        <span v-if="!editing" class="text__right">{{ user.name.first }}</span>
        <input v-else type="text" v-model="form.name.first" placeholder="firstname" />
      </label>
      <form-error v-if="editing" :message="errors.first" />
      <label :class="[errors.last ? 'form--error' : null]">
        <span class="text__bold">Last Name*</span>
        <span v-if="!editing" class="text__right">{{ user.name.last }}</span>
        <input v-else type="text" v-model="form.name.last" placeholder="lastname" />
      </label>
      <form-error v-if="editing" :message="errors.last" />
      <label :class="[errors.email ? 'form--error' : null]">
        <span class="text__bold">Email*</span>
        <span v-if="!editing" class="text__overflow text__right">
          <a :href="`mailto: ${user.email}`" class="link__none">{{ user.email }}</a>
        </span>
        <input v-else type="email" v-model="form.email" placeholder="test@fbhl.com" />
      </label>
      <form-error v-if="editing" :message="errors.email" />
      <label :class="[errors.phone ? 'form--error' : null]">
        <span class="text__bold">Phone*</span>
        <span v-if="!editing" class="text__overflow text__right">
          <span v-if="!user.phone">none provided</span>
          <a v-else :href="`tel: ${user.phone}`" class="link__none">{{ user.phone }}</a>
        </span>
        <input v-else type="tel" v-model="form.phone" placeholder="(123) 456-7890" />
      </label>
      <form-error v-if="editing" :message="errors.phone" />
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["canEdit", "user"],
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.user ? this.user._id : null,
        name: {
          first: this.user ? this.user.name.first : null,
          last: this.user ? this.user.name.last : null
        },
        email: this.user ? this.user.email : null,
        phone: this.user ? this.user.phone : null
      },
      errors: {
        first: null,
        last: null,
        email: null,
        phone: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateUser"]),
    toggleEditing() {
      if (!this.canEdit) return;
      this.editing = !this.editing;
    },
    async submit() {
      if (!this.canEdit) return;
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        try {
          await this.startUpdateUser(this.form);
          this.startSetIsSubmitting({ bool: false });
          this.toggleEditing();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    emailTest(email) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      const reFBHL = /@fbhl.com/ && /@mollydeanteam.com/;
      return re.test(email) && reFBHL.test(email);
    },
    validateForm() {
      this.isValidated = true;
      this.errors.first =
        this.form.name.first === "" ? "Please enter a first name" : null;
      this.errors.last =
        this.form.name.last === "" ? "Please enter a last name" : null;
      this.errors.email = !this.emailTest(this.form.email)
        ? "Please enter a valid fbhl address"
        : null;
      this.errors.phone =
        this.form.phone && this.form.phone.length < 14
          ? "Please enter a full phone number"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.user-profile--info__grid {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 15px, $align: center);
  label {
    @include grid($cols: 1fr 1fr, $col-gap: 10px, $align: center);
  }
}
@media (min-width: $sm) {
  .user-profile--info__grid label {
    grid-template-columns: 1fr 2fr;
  }
}
</style>