<template>
  <section class="user-profile">
    <Header :user="getActiveUser" />
    <div class="container">
      <Information :canEdit="canEdit" :user="getActiveUser" />
      <section class="grid__two margin__md">
        <section class="margin__md tile">
          <h2 class="mb-15">Orders</h2>
          <order-grid :orders="shownOrders" />
          <router-link
            :to="`/admin/past-orders?name=${getActiveUser.displayName}`"
            class="breadcrumb text__right"
          >
            View All
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </section>
        <section class="user-profile--orders margin__md tile">
          <h2 class="mb-15">Favorites</h2>
          <favorite-grid :favorites="favoriteOrders" />
          <router-link
            :to="`/admin/past-orders?name=${getActiveUser.displayName}&favorite=true`"
            class="breadcrumb text__right"
          >
            View All
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </section>
      </section>
      <section class="margin__md">
        <BulkOrders />
      </section>
      <messages :user="getActiveUser" />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "./Header";
import Information from "./Information";
import BulkOrders from "./BulkOrders";
export default {
  components: {
    Header,
    Information,
    BulkOrders
  },
  metaInfo: {
    title: "User | WOW"
  },
  data() {
    return {
      adminAccess: ["super-admin"]
    };
  },
  watch: {
    async $route(to, from, next) {
      try {
        await this.startSetActiveUser(to.params.user);
      } catch (err) {
        next("/admin/users");
      }
    }
  },
  computed: {
    ...mapGetters(["getUserDetails", "getActiveUser", "getActiveUserOrders"]),
    canEdit() {
      if (this.getUserDetails) {
        return this.adminAccess.includes(this.getUserDetails.permission);
      }
      return false;
    },
    shownOrders() {
      return this.getActiveUserOrders.slice(0, 4);
    },
    favoriteOrders() {
      return this.getActiveUserOrders
        .filter(order => order.isFavorite)
        .sort((a, b) => {
          if (a.favoriteRank < b.favoriteRank) return -1;
          if (a.favoriteRank > b.favoriteRank) return 1;
          return 0;
        })
        .slice(0, 4);
    }
  },
  methods: {
    ...mapActions(["startSetActiveUser"])
  }
};
</script>

<style scoped lang="scss">
</style>