<template>
  <div class="tile">
    <h2 class="mb-15">Batch Orders</h2>
    <div v-if="getUserBulkOrders.length <= 0" class="padding__md flex__col bg-light-grey">
      <p class="text__bold text__center padding__sm">No batch orders found</p>
    </div>
    <ul v-else class="bulk-orders--list">
      <li
        class="bulk-orders--item tile order-tile"
        v-for="batch in getUserBulkOrders"
        :key="batch._id"
      >
        <div class="order-row">
          <span>Created:</span>
          <span class="text__overflow">{{ cleanDate(batch.createdAt) }}</span>
        </div>
        <div class="order-row">
          <span>Batch Name:</span>
          <span>{{ batch.name }}</span>
        </div>
        <div class="order-row">
          <span>Billing Name:</span>
          <span class="text__overflow">{{ batch.billingName }}</span>
        </div>
        <div class="order-row">
          <span>Order Count:</span>
          <span class="text__bold text__green">{{ batch.bulkItems.length }}</span>
        </div>
        <div class="order-row">
          <span>Last Update:</span>
          <span class="text__overflow">{{ cleanDate(batch.updatedAt) }}</span>
        </div>
        <div class="order-comments">
          <span class="text__bold">Comments:</span>
          <span class="pre-wrap">{{ batch.comments }}</span>
        </div>
        <div class="flex__right text__bold">
          <router-link :to="`/batch-order/${batch._id}/batch-items`" class="link__none">
            View
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  computed: {
    ...mapGetters(["getUserBulkOrders"])
  },
  methods: {
    cleanDate(date) {
      return moment(date).format("MM-DD-YYYY hh:mma");
    }
  }
};
</script>

<style scoped lang="scss">
.flex__col {
  align-items: center;
}
.bulk-orders--list {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px);
}
@media (min-width: $md) {
  .bulk-orders--list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .bulk-orders--list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>