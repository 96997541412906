<template>
  <section class="user-profile--header">
    <div class="container">
      <div class="flex__between">
        <router-link to="/admin/users" class="breadcrumb">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Users
        </router-link>
        <!-- <button
          class="btn btn__sm btn__red mt-15"
          @click="emitToggleDeleting"
          disabled="true"
        >Delete</button>-->
      </div>
      <h1 class="text__center padding__sm">{{ user.displayName }}'s Profile</h1>
      <p class="header--message">
        Changes made to this profile take effect immediately, cascading to the associated signed-in user, and some changes cannot be undone.
        Be sure that your actions will not damage how the user accesses or uses this website, especially when it comes to email.
        <br />
        <br />
        <i>Please do not create test users as deleting them will be very difficult and can potentially cause issues with order data</i>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: ["user"],
  methods: {
    emitToggleDeleting() {
      this.$emit("toggleDeleting");
    }
  }
};
</script>

<style scoped lang="scss">
</style>