<template>
  <section class="tile">
    <div class="flex__between">
      <h2>Admin Info</h2>
      <button class="btn btn__red btn__sm" @click="toggleEditing">{{ editing ? "Cancel" : "Edit"}}</button>
    </div>
    <p>
      <router-link to="/admin/help">Happy Fox ID</router-link>&nbsp;comes from HTML in Happy Fox, it is only necessary for admin users so they can reply to tickets via this website.
    </p>
    <div class="user-profile--info__grid m-tb-sm">
      <label :class="[errors.permission ? 'form--error' : null]">
        <span class="text__bold">Permissions*</span>
        <span v-if="!editing" class="text__right">{{ user.permission }}</span>
        <select v-else v-model="form.permission">
          <option value="user">User</option>
          <option value="admin">Admin</option>
          <option value="super-admin">Super Admin</option>
          <option value="management">Management</option>
          <option value="asm">ASM</option>
        </select>
      </label>
      <form-error v-if="editing" :message="errors.permission" />
      <label>
        <span class="text__bold">Enabled*</span>
        <span v-if="!editing" class="text__right">{{ user.enabled ? "Yes" : "No" }}</span>
        <select v-else v-model="form.enabled">
          <option :value="true">Yes</option>
          <option :value="false">No</option>
        </select>
      </label>
      <label>
        <span class="text__bold">Batch Enabled*</span>
        <span v-if="!editing" class="text__right">{{ user.bulkEnabled ? "Yes": "No" }}</span>
        <select v-else v-model="form.bulkEnabled">
          <option :value="true">Yes</option>
          <option :value="false">No</option>
        </select>
      </label>
      <label :class="[errors.hfId ? 'form--error' : null]">
        <span class="text__bold">Happy Fox ID*</span>
        <span v-if="!editing" class="text__right">{{ user.hfUserId ? user.hfUserId : "none set" }}</span>
        <input v-else type="number" min="0" max="150" v-model="form.hfUserId" />
      </label>
      <form-error v-if="editing" :message="errors.hfId" />
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["canEdit", "user"],
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.user ? this.user._id : null,
        permission: this.user ? this.user.permission : null,
        enabled: this.user ? this.user.enabled : null,
        bulkEnabled: this.user ? this.user.bulkEnabled : null,
        hfUserId: this.user ? this.user.hfUserId : null
      },
      errors: {
        hfUserId: null
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateUser"]),
    toggleEditing() {
      if (!this.canEdit) return;
      this.editing = !this.editing;
    },
    async submit() {
      if (!this.canEdit) return;
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        try {
          await this.startUpdateUser(this.form);
          this.startSetIsSubmitting({ bool: false });
          this.toggleEditing();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validateForm() {
      if (!this.form.hfUserId) {
        this.errors.hfUserId = null;
      } else {
        this.form.hfUserId = parseInt(this.form.hfUserId);
        this.isValidated = true;
        this.errors.hfUserId = !(
          this.form.hfUserId > 0 &&
          this.form.hfUserId < 150 &&
          !isNaN(this.form.hfUserId)
        )
          ? "Enter a valid Happy Fox Id"
          : null;
      }
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.user-profile--info__grid {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 15px, $align: center);
  label {
    @include grid($cols: 1fr 1fr, $col-gap: 10px, $align: center);
  }
}
@media (min-width: $sm) {
  .user-profile--info__grid label {
    grid-template-columns: 1fr 2fr;
  }
}
</style>