<template>
  <section class="user-profile--award tile">
    <div class="flex__between">
      <h2>Award Balance</h2>
      <button class="btn btn__sm btn__red" @click="toggleEditing">{{ editing ? "Cancel" : "Edit"}}</button>
    </div>
    <p>You may not reduce the balance below 0. Be advised there is no history of changes being recorded.</p>
    <div class="user-profile--award__grid padding__sm">
      <div class="flex__center m-tb-sm">
        <span v-if="!editing" class="text__bold mr-10">Balance:</span>
        <span class="award-bal">${{ user.awardBalance.toFixed(2) }}</span>
        <p v-if="editing" class="award-change text__bold">
          <span class="award-math">
            <span class="m-lr-sm">{{ selection === "increase" ? "+" : "-" }}</span>
            <span>{{ parseFloat(awardChange).toFixed(2) }}</span>
            <span class="m-lr-sm">=</span>
          </span>
        </p>
        <span
          v-if="editing"
          class="final-bal"
          :class="[isNaN(finalBalance) ? 'final-bal--error' : null]"
        >{{ isNaN(finalBalance) ? finalBalance : `$${finalBalance.toFixed(2)}` }}</span>
      </div>
      <div class="padding__sm">
        <div v-if="editing" class="balance-change flex__center">
          <label for="decrease" class="flex__center shadow">
            <input
              type="radio"
              name="balance"
              id="decrease"
              class="input--hide"
              value="decrease"
              v-model="selection"
            />
            <span class="btn btn__sm">-</span>
          </label>
          <input type="number" v-model="awardChange" min="0" max="500" />
          <label for="increase" class="flex__center shadow">
            <input
              type="radio"
              name="balance"
              id="increase"
              class="input--hide"
              value="increase"
              v-model="selection"
            />
            <span class="btn btn__sm">+</span>
          </label>
        </div>
        <div class="flex__center">
          <form-error v-if="editing" :message="errors.awardBalance" />
        </div>
      </div>
    </div>
    <div v-if="editing" class="flex__between">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="submit">Done</button>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["canEdit", "user"],
  data() {
    return {
      editing: false,
      selection: "increase",
      awardChange: 0,
      form: {
        _id: this.user ? this.user._id : null,
        awardBalance: this.user ? this.user.awardBalance : null
      },
      errors: {
        awardBalance: null
      }
    };
  },
  computed: {
    finalBalance() {
      const change = parseFloat(this.awardChange);
      if (isNaN(change)) {
        return "Error";
      }
      if (change < 0) {
        return "Error";
      }
      if (this.selection === "increase") {
        return this.form.awardBalance + change;
      }
      if (this.form.awardBalance - change < 0) {
        return "Error";
      }
      return this.form.awardBalance - change;
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateUser"]),
    async submit() {
      if (!this.canEdit) return;
      this.validateForm();
      if (this.errors.awardBalance === null) {
        this.startSetIsSubmitting({ bool: true, heading: "Updating" });
        this.form.awardBalance = this.finalBalance;
        try {
          await this.startUpdateUser(this.form);
          this.startSetIsSubmitting({ bool: false });
          this.awardChange = 0;
          this.selection = "increase";
          this.toggleEditing();
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validateForm() {
      const change = parseFloat(this.awardChange);
      this.errors.awardBalance = null;
      if (isNaN(change)) {
        this.errors.awardBalance = "Please enter a number";
        return;
      }
      if (change <= 0) {
        this.errors.awardBalance = "Please enter a number > 0";
        return;
      }
      if (this.selection !== "increase") {
        if (this.form.awardBalance - change < 0) {
          this.errors.awardBalance = "Award balance cannot be less than 0";
          return;
        }
      }
    },
    toggleEditing() {
      if (!this.canEdit) return;
      this.editing = !this.editing;
    }
  }
};
</script>

<style scoped lang="scss">
.user-profile--award {
  height: 100%;
}
.award-change {
  @include flex($just: center, $align: center);
  height: 44px;
}
.award-bal {
  font-size: $text-md;
  font-weight: bold;
}
.final-bal {
  font-weight: bold;
  color: #fff;
  padding: 3px 8px;
  border-radius: 3px;
  font-size: $text-lg;
  background-color: $black;
}
.final-bal--error {
  background-color: $maroon;
}
.balance-change {
  @include grid($cols: 1fr 2fr 1fr, $align: center);
  label {
    width: 100%;
    height: 100%;
  }
  span {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    background-color: $black;
    color: #fff;
    font-weight: bold;
    font-size: $text-md;
  }
}
input[type="radio"]:checked + span {
  background: $green;
}
.input--hide {
  display: none;
}
</style>