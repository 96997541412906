<template>
  <section class="user-profile--info margin__md">
    <UserInfo :canEdit="canEdit" :user="user" />
    <AdminInfo :canEdit="canEdit" :user="user" />
    <Delegates :canEdit="canEdit" :user="user" />
    <AwardBalance :canEdit="canEdit" :user="user" />
  </section>
</template>

<script>
import UserInfo from "./UserInfo";
import AdminInfo from "./AdminInfo";
import Delegates from "./UserDelegates";
import AwardBalance from "./AwardBalance";
export default {
  props: ["canEdit", "user"],
  components: {
    UserInfo,
    AdminInfo,
    Delegates,
    AwardBalance
  }
};
</script>

<style scoped lang="scss">
.user-profile--info {
  @include grid($cols: 1fr, $row-gap: 50px, $col-gap: 50px);
}
@media (min-width: $md) {
  .user-profile--info {
    grid-template-columns: 1fr 1fr;
  }
}
</style>